








































import Vue from 'vue'
import Component from 'vue-class-component'
import {BSpinner} from "bootstrap-vue";
import { Prop } from "vue-property-decorator";

Component.registerHooks(['validations'])
@Component({
  components: { BSpinner },
})

export default class EditProfile extends Vue {
  @Prop() isLoading!: boolean;
}
