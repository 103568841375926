var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "edit-profile"
  }, [_vm._t("modal"), _c('div', {
    staticClass: "edit-profile__wrapper"
  }, [_c('div', {
    staticClass: "edit-profile__box"
  }, [_c('div', {
    class: ['edit-profile__loader', _vm.isLoading ? 'active' : '']
  }, [_c('div', {
    staticClass: "spinner"
  }, [_c('b-spinner')], 1)]), _c('div', {
    staticClass: "content"
  }, [_vm._t("content")], 2)]), _c('div', {
    staticClass: "edit-profile__sidebar"
  }, [_c('div', {
    staticClass: "edit-profile-sidebar"
  }, [_c('router-link', {
    staticClass: "edit-profile-sidebar__item",
    attrs: {
      "to": {
        path: 'general'
      }
    }
  }, [_c('span', [_vm._v("General data")])]), _c('router-link', {
    staticClass: "edit-profile-sidebar__item",
    attrs: {
      "to": {
        path: 'detailed'
      }
    }
  }, [_c('span', [_vm._v("Detailed data")])]), _c('router-link', {
    staticClass: "edit-profile-sidebar__item",
    attrs: {
      "to": {
        path: 'address'
      }
    }
  }, [_c('span', [_vm._v("Address data")])]), _c('router-link', {
    staticClass: "edit-profile-sidebar__item",
    attrs: {
      "to": "/regulations",
      "target": "_blank"
    }
  }, [_c('span', [_vm._v("Regulations")])]), _c('router-link', {
    staticClass: "edit-profile-sidebar__item",
    attrs: {
      "to": {
        path: 'security'
      }
    }
  }, [_c('span', [_vm._v("Security")])])], 1), _c('router-link', {
    attrs: {
      "to": {
        name: 'profile-dashboard'
      }
    }
  }, [_c('a', {
    staticClass: "edit-profile__link"
  }, [_vm._v("See your profile")])])], 1)])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }